<template>
  <div id="nav" v-if="verify">
    <router-link to="/">Home</router-link> |
    <router-link to="/drama-place">Drama Place</router-link>
  </div>
  <router-view/>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      verify: false
    };
  },
  created: function () {
    if (localStorage.getItem('internet-connect')) this.verify = true;
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
