<template>
    <br><br>
    <iframe frameborder="0" :src="link" allowfullscreen ></iframe>
</template>

<script>
export default {
  name: 'Video',
  props: {
    link: String
  }
}
</script>

<style scoped>

iframe {
  width: 80vw;
  height: 360px;
  overflow: hidden;
}

</style>
