<template>
  <div class="home" v-if="!verify">
    <h1>Veuillez verifier votre identité..</h1>
    <h3>Des personnes ne veulent pas que certains fichiers qui se trouvent ici soient publiés sur le net. <br> Donc nous allons vérifier votre identité.</h3>

    <br><br><br><br><br><br><br><br>
    <h2>Veuillez entrer le nom puis le prénom du professeur d'anglais des 3e3 2021 :</h2>
    <p>Exemple : Mark Zuckerberg</p>

    <input v-model="name" type="text" id="name" name="name" required
       minlength="4" size="30" @change="check()">

  </div>
  <div v-else>
    <h1>Lien vers la pièce de théâtre : </h1> <router-link to="/drama-place">Clique ici !</router-link>
  </div>
</template>

<script>

export default {
  name: 'Home',
  data() {
    return {
      verify: localStorage.getItem('internet-connect'),
      name: null,
      prof: "David Jannin"
    };
  },
  methods: {
    check() {
      if(this.name === this.prof) {
        localStorage.setItem('internet-connect', true)
        window.location.reload();
      }
    }
  },
}
</script>
