<template>

  <h1>Welcome to the drama play:</h1>
  <h2>The Crime !</h2>

  <br><br><br>

  <h3>Sélectionner la pièce que vous voulez regarder :</h3>
  <select v-model="scene" @change="handleChange($event)">
    <option value="1">Scène 1</option>
    <option value="2-1">Scène 2 | Partie 1</option>
    <option value="2-2">Scène 2 | Partie 2</option>
    <option value="3">Scène 3</option>
  </select>

  <Video :link="link"/>

  <br><br><br><br>
  <a href="https://mega.nz/file/3JJyRDKI#JmSxX7M33eDK0x4sY7t0fz8JtvuKbLbOx3WeVUVBpho" target="_blank">Télécharger toutes les scènes ici ! (4,78 Go)</a>

</template>

<script>

import Video from '@/components/Video.vue'

export default {
  name: 'DramaPlace',
  data() {
    return {
      verify: localStorage.getItem('internet-connect'),
      scene: 1,
      link: "https://www.dailymotion.com/embed/video/k2of87eHD6QNX4xdPZZ"
    };
  },
  components: {
    Video
  },
  created: function () {
    if(!this.verify) this.$router.push('/')
    this.scene = localStorage.getItem('scene')
    if (this.scene === "2") this.link = "https://www.dailymotion.com/embed/video/ksRL7btf28ohDZxdQ0f"
    if (this.scene === "2-1") this.link = "https://www.dailymotion.com/embed/video/ksRL7btf28ohDZxdQ0f"
    if (this.scene === "2-2") this.link = "https://www.dailymotion.com/embed/video/k2LmyxpOY0cKA6xdQ0g"
    if (this.scene === "3") this.link = "https://www.dailymotion.com/embed/video/k3vAJnTR7ZgduYxdQ0j"
  },
  methods: {
    handleChange(event) {
      localStorage.setItem('scene', event.target.value);
      window.location.reload();
    }
  },
}
</script>
