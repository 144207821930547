import { createRouter, createWebHashHistory } from 'vue-router'

import Home from '../views/Home.vue'
import DramaPlace from '../views/DramaPlace.vue'


const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/drama-place',
    name: 'DramaPlace',
    component: DramaPlace
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
